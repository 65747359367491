<template>
  <div>
    <el-form
        :inline="true"
        :model="query"
    >
      <el-form-item label="群ID" prop="groupId">
        <el-input v-model="query.groupId" placeholder="请输入群id"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="auditShow()" type="primary">开始审核</el-button>
      </el-form-item>
    </el-form>

    <span
        v-if="countShow">今日进审数量 {{ reportCount.todayReportCount }}，今日审核数量 {{ reportCount.todayHandleCount }}，当前积压总量 {{ reportCount.totalUnHandleCount }}，今日进审且积压量 {{ reportCount.totalUnHandleCount }}</span>

    <el-form class="info" :model="showData" v-if="show">

      <el-row>
        <h3>【群信息】</h3>
        <el-col :span="12" class="info">
          <el-form-item>
            <img v-if="showData.groupDto" :src="showData.groupDto.groupAvatar" class="avatar">
          </el-form-item>
          <el-form-item label="群id：">
            {{ this.showData.groupDto.groupId }}
          </el-form-item>
          <el-form-item label="群名称：">
            {{ this.showData.groupDto.groupName }}
          </el-form-item>
          <el-form-item label="群人数：">
            {{ this.showData.groupDto.memberCount }}
          </el-form-item>
          <el-form-item label="群今日活跃人数：">
            {{ this.showData.groupDto.todayActiveCount }}
          </el-form-item>
          <el-form-item label="创建时间：">
            {{ this.showData.groupDto.createTime }}
          </el-form-item>
        </el-col>


        <h3>【命中敏感词分类】</h3>
        <el-col :span="12" class="info">
          <el-table
              :data="showCount"
              style="width: 100%">
            <el-table-column
                prop="key"
                label="违规类型"
                width="180">
            </el-table-column>
            <el-table-column
                prop="value"
                label="违规数量"
                width="180">
            </el-table-column>
          </el-table>

        </el-col>
      </el-row>
    </el-form>

    <el-form
        ref="submitForm"
        :model="auditHandleForm"
        v-if="show"
    >
      <el-form-item
          label="处理方式："
          prop="handleMode">
        <el-radio-group v-model="auditHandleForm.handleMode">
          <el-radio v-for="item in handleMoles"
                    :key="item.handleMode" :label="item.handleMode" border>
            {{ item.desc }}
          </el-radio>
        </el-radio-group>
      </el-form-item>


      <el-form-item >
        <div class="submit-btn">
          <el-button type="warning" @click="ignoreCurrent">跳过</el-button>
          <el-button type="primary" @click="handleAudit">提交</el-button>
        </div>
      </el-form-item>
    </el-form>

  </div>

</template>

<script>

const defaultShowData = {
  id: 0,
  auditType: -1,
  groupDto: {
    groupId: '',
    groupName: '',
    createTime: '',
    groupAvatar: '',
    memberCount: 0,
    todayActiveCount: 0
  },
  localThesaurusCount: 0,
  hateCount: 0,
  selfHarmCount: 0,
  sexualCount: 0,
  sexualMinorsCount: 0,
  violenceCount: 0,
  total: 0,
  description: '',
  createTime: ''
}


export default {
  name: 'AuditSystemGroupBase',

  props: {
    // TODO
    auditTypes: Array,

    // [{handleMode: xxx, desc: xxxx, config: xxx}]
    handleMoles: Array,
    defaultHandleMole: String,
    title: String

  },
  data() {

    return {
      show: false,
      countShow: false,
      query: {
        groupId: '',
        auditTypes: this.auditTypes,
        ignoreIds: []
      },
      auditHandleForm: {
        reportId: 0,
        handleMode: 'IGNORE'
      },
      showData: JSON.parse(JSON.stringify(defaultShowData)),
      reportCount: {
        todayReportCount: 0,
        todayHandleCount: 0,
        todayUnHandleCount: 0,
        totalUnHandleCount: 0
      },
      showCount: []
    };
  },
  components: {
  },
  methods: {
    showOrRefreshCount() {
      this.$http({
        url: this.$http.adornUrl('/admin/audit/auditCount'),
        method: 'post',
        data: this.$http.adornParams({
          auditTypes: this.query.auditTypes
        }),
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.reportCount = data.result
          this.countShow = true;
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })
        }
      })

    },
    handleAudit() {
      this.auditHandleForm.reportId = this.showData.id
      this.$refs['submitForm'].validate((valid) => {
        if (valid) {
          this.$confirm('是否确认对该群进行处罚？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.realSubmit();
          })
        }
      })
    },
    realSubmit(){
      // 处理配置
      const obj = this.handleMoles.find(item => item.handleMode === this.auditHandleForm.handleMode);
      const config = obj ? obj.config : null;
      this.$http({
        url: this.$http.adornUrl('/admin/audit/handleAudit'),
        method: 'post',
        data: this.$http.adornParams({
          reportId: this.auditHandleForm.reportId,
          handleMode: this.auditHandleForm.handleMode,
          handleConfig: config
        }),
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$message({
            message: "审核成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.auditShow()
            },
          });
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })
        }
      })
    },
    auditShow() {
      this.showOrRefreshCount()
      this.$http({
        url: this.$http.adornUrl('/admin/audit/groupAuditShow'),
        method: 'post',
        data: this.$http.adornParams({
          auditTypes: this.query.auditTypes,
          ignoreIds: this.query.ignoreIds,
          groupId: this.query.groupId
        }),
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.showData = data.result
          this.show = true;
          this.showCount = [
            {
              key: '语言暴力/骚扰',
              value: this.showData.hateCount
            },
            {
              key: '表达/煽动自杀',
              value: this.showData.selfHarmCount
            },
            {
              key: '色情',
              value: this.showData.sexualCount
            },
            {
              key: '未成年色情',
              value: this.showData.sexualMinorsCount
            },
            {
              key: '肢体暴力',
              value: this.showData.violenceCount
            },
            {
              key: '本地词库',
              value: this.showData.localThesaurusCount
            },
            {
              key: 'all',
              value: this.showData.total
            }
          ]
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })
          this.showData = JSON.parse(JSON.stringify(defaultShowData))
          this.show = false
        }
      })
    },
    ignoreCurrent() {
      this.query.ignoreIds.push(this.showData.id)
      this.auditShow();
    },
  }
}
</script>

<style scoped lang="scss">
.avatar {
  width: 100px;
  height: 100px;
}

.info {
  margin-top: 20px;
}
.submit-btn {
  text-align: center;
}
</style>

